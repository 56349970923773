import { LoadingArea } from '@consigli/facade';
import { useMyUser } from '@consigli/hooks';
import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Message } from '@/util/interfaces';

import { ChatMessage } from './chat-message';

interface ChatMessagesProps {
  loading: boolean;
  disabled: boolean;
  messages: Message[];
}
export const ChatMessages: FC<ChatMessagesProps> = ({ disabled, loading, messages }) => {
  const { t } = useTranslation();
  const { user } = useMyUser();
  if (loading) {
    return <LoadingArea />;
  } else if (disabled) {
    return (
      <div className="flex flex-col justify-center items-center pt-40">
        <div className="p-16 mx-8 text-xl bg-day-light-3 rounded-md">{t('prop-chat.disabled')}</div>
      </div>
    );
  } else {
    return (
      <>
        {messages.map((message, index) => {
          if (message.authorId === user.id) {
            if (index === messages.length - 1) {
              return (
                <Fragment key={message.id}>
                  <ChatMessage message={message} />
                  <ChatMessage loading message={message} from="SYSTEM" />
                </Fragment>
              );
            } else {
              return <ChatMessage key={message.id} message={message} />;
            }
          } else {
            return <ChatMessage key={message.id} message={message} from="SYSTEM" />;
          }
        })}
      </>
    );
  }
};
